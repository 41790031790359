
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "src/app/authentication/authentication.style.scss";
@import "src/app/card/card.style.scss";
@import "src/app/account/account.style.scss";
@import "commons/commons.style.scss";
@import "assets/styles/material-override";
@import "src/app/statement/statement.style.scss";

@import "bootstrap/scss/bootstrap";

@import "@ng-select/ng-select/themes/default.theme.css";
@import "assets/styles/ng-select-override";

@import "@fontsource/heebo/100.css";
@import "@fontsource/heebo/200.css";
@import "@fontsource/heebo/300.css";
@import "@fontsource/heebo/400.css";
@import "@fontsource/heebo/500.css";
@import "@fontsource/heebo/600.css";
@import "@fontsource/heebo/700.css";
@import "@fontsource/heebo/800.css";
@import "@fontsource/heebo/900.css";

/*Swiper Angular - Used for Marketing Section on Home Page*/
@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";
@import "swiper/scss/zoom";
@import "@ionic/angular/css/ionic-swiper";

html {
  --ion-font-family: "Heebo" !important;
}

//Remove ionic default tooltips
ion-icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

:root {
  --wrapper-height: 376px;
  --max-width: 1470px;

  @media only screen and (min-width: 0px) and (max-width: 767px) {
    --padding-desktop: 0px 0px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    --padding-desktop: 0px 20px;
  }

  @media only screen and (min-width: 960px) and (max-width: 1199px) {
    --padding-desktop: 0px 65px;
  }

  @media only screen and (min-width: 1200px) {
    --padding-desktop: 0px 135px;
  }

  /** primary **/
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.idle-modal {
  --width: 400px;
  --height: 210px;
}

.error-modal {
  --width: 450px;
  --height: 325px;
}

.html-modal {
  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --width: 80vw;
    --height: 80vh;
  }
}

.custom-alert {
  --width: 400px;
  --height: 220px;

  @media only screen and (min-width: 0px) and (max-width: 767px) {
    --width: 80vw;
    --height: 220px;
  }
}

.html-modal-full-screen {
  --width: 100dvw;
  --height: 100dvh;

  ion-content {
    iframe {
      height: 100dvh !important;
    }
  }
}

.confirmation-prompt {
  .alert-input-wrapper {
    border: 1px solid var(--mainTextColor);
    padding: 0px 10px;
    border-radius: 10px;
  }

  .alert-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .alert-sub-title {
    font-size: 14px;
  }

  @media only screen and (min-width: 576px) {
    --min-width: 400px;
  }

  .alert-wrapper {
    .alert-button {
      color: var(--callToActionColor);
    }
  }
}

/* Enable darkmode in a Bootstrap Page by switching colors */
@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--bs-dark) !important;
    color: var(--bs-light) !important;
  }
}

/* Pace chat integration */
#pace-webchat-root {
  #widgetContainer {
    position: absolute;
    bottom: 0;
  }
}

#amazon-connect-chat-widget button {
  display: none !important;
}

/* Enable darkmode in a Bootstrap Page by switching colors */
@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--bs-dark) !important;
    color: var(--bs-light) !important;
  }
}

// Disables the password reveal button in the password input field (MS Edge)
::-ms-reveal {
  display: none;
}

.outflow-transaction {
  color: $seconday-infrared-wcag;
  font-size: 17px;
}

.inflow-transaction {
  color: $seconday-forrestgreen;
  font-size: 17px;
}

.blocked-status {
  color: $seconday-infrared-wcag;
  font-weight: bold;
}

.active-status {
  color: $seconday-forrestgreen !important;
  font-weight: normal;
}

.closed-status {
  color: $seconday-infrared-wcag !important;
  font-weight: normal;
}

.attention-status {
  color: $seconday-gold !important;
  font-weight: normal;
}

ion-item.item-has-focus {
  border: 2px solid var(--ion-color-tertiary) !important;
}



html, body { height: 100%; }
